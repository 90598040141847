<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-select v-model="productTypeId" placeholder="商品类型" style="width: 240px;"
                        @change="loadProducts(true)">
                        <el-option :value="null" label="全部商品类型" />
                        <el-option v-for="productType in productTypes" :key="productType.id" :value="productType.id"
                            :label="productType.name">
                            <span :style="{marginLeft: (productType.id.length-4)*4+'px'}">{{productType.name}}</span>
                        </el-option>
                    </el-select>
                    <search-box v-model="key" class="ml-2" style="width: 240px;" @search="loadProducts(true)" />
                </div>
            </div>
            <el-table :data="products.data" class="mt-2">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.pictureUrl" :preview-src-list="[scope.row.pictureUrl]" fit="fill"
                            style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column label="名称">
                    <template #default="scope">
                        <div :title="scope.row.name" class="text-truncate">
                            <router-link :to="'/product/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">
                            [{{scope.row.productTypeName}}]
                            {{scope.row.specification}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="库存量" width="120" />
                <el-table-column prop="minQuantity" label="最低库存量" width="120" />
                <el-table-column prop="maxQuantity" label="最高库存量" width="120" />
                <el-table-column prop="unit" label="单位" width="120" />
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="text" @click="showUpdateProductStockDialog(scope.row)">
                            修改库存
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="products.totalRecords"
                :current-page="productsPagination.currentPage" :page-size="productsPagination.pageSize" class="mt-3"
                @current-change="productsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="updateProductStockDialogVisible" title="修改库存" :close-on-click-modal="false"
            width="400px">
            <el-form>
                <el-form-item label="期初库存量">
                    <el-input-number v-model="product.beginningQuantity" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="最低库存量">
                    <el-input-number v-model="product.minQuantity" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="最高库存量">
                    <el-input-number v-model="product.maxQuantity" controls-position="right" :min="0" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="updateProductStock">确定</el-button>
                <el-button @click="updateProductStockDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productTypes: [],
                productTypeId: null,
                key: null,
                products: {},
                productsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                product: {},
                updateProductStockDialogVisible: false,
            };
        },
        methods: {
            async loadProductTypes() {
                let response = await this.$axios.get('/api/ProductType/GetProductTypes');
                this.productTypes = response.data;
            },
            async loadProducts(resetPage = false) {
                if (resetPage) {
                    this.productsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Product/GetStockProducts', {
                    params: {
                        supplierId: this.$store.state.user.id,
                        productTypeId: this.productTypeId,
                        key: this.key,
                        pageIndex: this.productsPagination.currentPage - 1,
                        pageSize: this.productsPagination.pageSize,
                    }
                });
                this.products = response.data;
            },
            productsPaginationCurrentChange(page) {
                this.productsPagination.currentPage = page;
                this.loadProducts();
            },
            showUpdateProductStockDialog(product) {
                this.product = JSON.parse(JSON.stringify(product));
                this.updateProductStockDialogVisible = true;
            },
            async updateProductStock() {
                await this.$axios.post('/api/Product/UpdateProductStock', {
                    id: this.product.id,
                    beginningQuantity: this.product.beginningQuantity,
                    minQuantity: this.product.minQuantity,
                    maxQuantity: this.product.maxQuantity,
                });
                this.updateProductStockDialogVisible = false;
                this.loadProducts();
            },
        },
        created() {
            this.loadProductTypes();
            this.loadProducts();
        },
    };
</script>